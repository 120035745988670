import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Drawer, Collapse, useTheme } from "@mui/material";
import Content from "./components/Content";
import useStores from "../../../../../../../useStores";
import { MIN_SCREEN_WIDTH } from "../../constants";

const Menu = ({ expanded, setExpanded }) => {
  const { MenuStore } = useStores();

  const [mouseOver, setMouseOver] = useState(false);

  const theme = useTheme();

  const handleWindowResize = useCallback(() => {
    const width = window.innerWidth;

    const expanded = width >= MIN_SCREEN_WIDTH;

    setExpanded(expanded);
    MenuStore.changeCollapsed(!expanded);
  }, []);

  useEffect(() => {
    handleWindowResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <Content
        expanded={mouseOver || expanded}
        onMouseOver={() => !expanded && setMouseOver(true)}
        onMouseLeave={() => !expanded && setMouseOver(false)}
      />
    </>
  );
};

export default observer(Menu);
