import userService from "@core/api/user-service";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Avatar from "@core/components/ColoredModuleAvatar";
import { Box } from "@mui/material";
import useStores from "../../../../../../../../../../../useStores";

const Account = ({ expanded }) => {
  const { UserStore } = useStores();
  const user = UserStore.user.data;

  useEffect(() => {
    if (user.avatar && user.avatar.file) return;

    userService.getUserInfo().then((user) => {
      if (!user._id) return;

      UserStore.setUser(user);
    });
  }, []);

  const [module] = user.company.modules;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "4px",
        padding: 0.5,
        width: "100%",
        height: "80px",
      }}
    >
      <Box
        sx={{
          marginLeft: 0.2,
          "& div": {
            width: 30,
            height: 30,
          },
        }}
      >
        <Avatar user={user} module={module.name} />
      </Box>
      {expanded && (
        <Box sx={{ marginLeft: 1.5 }}>
          <Box
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {user.firstname} {user.lastname}
          </Box>
          <Box
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {user.company.name}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default observer(Account);
