import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useStores from "../../../../../../src/useStores";

const NotActivatedMessage = () => {
  // const allowUserNotActiveMessage = JSON.parse(
  //   localStorage.getItem("allowUserNotActiveMessage"),
  // );

  // Do not show message if user role is SUPER_ADMIN
  const { UserStore } = useStores();
  const role = UserStore.user.data.role;

  const [open, setOpen] = useState(!role === "SUPER_ADMIN");

  return (
    <Dialog open={open}>
      <DialogTitle>
        Welcome to the SteelTrace! Please follow this steps to activate your
        account:
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <List>
            <ListItem disableGutters>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary="Login into the SteelTrace mobile app" />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary="Go through the mnemonic process to generate your wallet" />
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.setItem("allowUserNotActiveMessage", false);
            setOpen(false);
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotActivatedMessage;
