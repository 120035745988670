import referenceService from "@core/api/reference-service";
import WS from "@core/api/socketConnection";
import userService from "@core/api/user-service";
import CompanyForm from "@core/components/CompanyForm";
import EntityNotFound from "@core/components/EntityNotFound";
import Loader from "@core/components/Loader";
import ConfirmationModal from "@core/components/Modal/Confirmation/Confirmation";
import modules from "@core/constants/modules";
import roles from "@core/constants/roles";
import { getBCPermissions } from "@core/helpers";
import { Button, Grid, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";
import { compose, filter } from "ramda";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import CompanyBCService from "../../Blockchain/companies-service";
import Employees from "./components/Employees";
import styles from "./styles";

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activationProcess: false,
      removeConfirmationOpened: false,
    };
  }

  handleRemoveCompany = () => {
    const id = this.props.match.params.id;
    this.props.CompanyStore.deleteCompany(id).then(() =>
      this.props.history.push("/companies"),
    );
  };

  render() {
    const {
      classes,
      CompanyStore,
      match: { params },
    } = this.props;
    const { activationProcess, removeConfirmationOpened } = this.state;
    const company = CompanyStore.company.data;
    const companyId = params.id;

    if (!CompanyStore.company.isLoaded) {
      return <Loader />;
    }

    if (CompanyStore.company.isEmpty) {
      return <EntityNotFound entity="Company" />;
    }

    const initialValues = {
      name: company.name,
      image: company.image,
      country: company.mainLocation ? company.mainLocation.country : "",
      city: company.mainLocation ? company.mainLocation.city : "",
      street: company.mainLocation ? company.mainLocation.street : "",
      house: company.mainLocation ? company.mainLocation.house : "",
      postalCode: company.mainLocation ? company.mainLocation.postalCode : "",
      plantDescription: company.mainLocation
        ? company.mainLocation.plantDescription
        : "",
      admin: company.admin,
      logo: company.logo,
      phone: company.phone,
      fax: company.fax,
      email: company.email,
      website: company.website,
      partners: company.partners,
      approvals: company.approvals,
      modules: company.modules,
      features: company.features,
      locations: filter(
        (location) => location._id !== company.mainLocation?._id,
        company.locations,
      ),
    };

    const changePermissions = ({ company, user }) => {
      const { SigningStore } = this.props;
      const permissions = getBCPermissions(
        company.modules[0].name,
        roles.ADMIN,
      );

      userService.getUserInfo().then(async (currentUser) => {
        try {
          const transactionObject = CompanyBCService.changeUserPermissionsTX(
            company._id,
            user.bc_address,
            permissions,
          );
          const reference = await referenceService.create({
            creator: currentUser._id,
            transactions: [
              {
                transactionObject: transactionObject,
                payload: {
                  type: "user",
                  action: "change",
                  historyData: {
                    editedUser: user.username,
                  },
                },
              },
            ],
            date_created: new Date(),
          });

          SigningStore.openSigner(
            { id: reference._id },
            {
              title: "Company activation",
              description:
                "The company admin should scan this QR code. By doing so the admin key is connected to the company.",
              blocked: true,
            },
          );
        } catch (e) {
          console.log(e);
        }
      });
    };

    const activateCompany = async (data) => {
      const { NotificationStore } = this.props;

      await CompanyStore.activate(data);

      changePermissions(data);

      NotificationStore.showSuccess("Successfully added!");

      this.setState({
        activationProcess: false,
      });
    };

    const onSubmit = async (values) => {
      const data = {
        name: values.name,
        image: values.image,
        locations: values.locations,
        mainLocation: {
          country: values.country,
          city: values.city,
          street: values.street,
          house: values.house,
          postalCode: values.postalCode,
          plantDescription: values.plantDescription,
        },
        logo: values.logo,
        phone: values.phone,
        fax: values.fax,
        email: values.email,
        website: values.website,
        partners: values.partners,
        approvals: values.approvals,
        features: values.features,
      };

      await CompanyStore.update(data, company._id);
    };

    const isActivate =
      company.modules[0]?.name === modules.SUPER_ADMIN ? true : company.active;
    const isCurrentUserSuperAdmin =
      this.props.UserStore.user.data.company.modules[0]?.name ===
      modules.SUPER_ADMIN;
    const isCompanySuperAdmin =
      company.modules[0]?.name === modules.SUPER_ADMIN;

    return (
      <div className={classes.p40}>
        <Grid
          container
          className={classes.mb25}
          spacing={3}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid item xs={2}>
            <Typography variant="h4" fontSize="1.8rem">
              Details View
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {isCurrentUserSuperAdmin && !isCompanySuperAdmin && (
              <Button
                variant="contained"
                color="secondary"
                size={"large"}
                onClick={() =>
                  this.setState({ removeConfirmationOpened: true })
                }
              >
                Delete company
              </Button>
            )}
          </Grid>

          {isActivate && (
            <Grid item xs={2}>
              <Button
                component={Link}
                to={`/companies/${companyId}/users/create`}
                variant="contained"
                color="primary"
              >
                Add users
              </Button>
            </Grid>
          )}

          {!isActivate && company.admin.bc_address && (
            <Grid item xs={1} className={classes.activateAdnSpinner}>
              <Button
                variant="contained"
                color="secondary"
                disabled={activationProcess}
                onClick={() => {
                  this.setState({
                    activationProcess: true,
                  });
                  activateCompany({ company, user: company.admin });
                }}
              >
                Activate
              </Button>
              {activationProcess && (
                <div className={classes.spinner}>
                  <Loader />
                </div>
              )}
            </Grid>
          )}
        </Grid>

        <div className={classes.companyForm}>
          <CompanyForm
            company={initialValues}
            disabled={!isActivate}
            onSubmit={onSubmit}
          />
        </div>

        <Employees urlId={companyId} />

        {removeConfirmationOpened && (
          <ConfirmationModal
            open={removeConfirmationOpened}
            onCancel={() => this.setState({ removeConfirmationOpened: false })}
            onConfirm={this.handleRemoveCompany}
            alertText={
              "The company will be irrevocably deleted as well as all of it's users. Are you sure you want to remove them?"
            }
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    const {
      SigningStore,
      CompanyStore,
      match: { params },
    } = this.props;
    CompanyStore.getCompanyById(params.id);

    WS.listen("transaction:user:change", () => {
      SigningStore.closeSigner();
      CompanyStore.getCompanyById(params.id);
    });
  }

  componentWillUnmount() {
    WS.remove("transaction:user:change");
  }
}

export default compose(
  inject("CompanyStore", "UserStore", "NotificationStore", "SigningStore"),
)(withStyles(observer(Details), styles));
